import { datadogLogs } from '@datadog/browser-logs'

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ?? 'unknown'
const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN
const LOGGER_SEND_LIST = ['production', 'staging', 'development']

export const initDataLog = (client_token: any) => {
  datadogLogs.init({
    clientToken: client_token,
    service: 'BackOffice',
    env: ENVIRONMENT,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    beforeSend: (log) => {
      // 出力不要なエラーを破棄
      if (log.message && log.message.indexOf('ResizeObserver loop limit exceeded') > -1) {
        return false
      }
    },
  })
  return datadogLogs
}

if (LOGGER_SEND_LIST.includes(ENVIRONMENT)) {
  if (DATADOG_CLIENT_TOKEN) {
    initDataLog(DATADOG_CLIENT_TOKEN)
    // ログイン済の場合はユーザ情報をセット
    const user = localStorage.getItem('currentUser')
    if (user) {
      const currentUser = JSON.parse(user)
      datadogLogs.setGlobalContextProperty('user', currentUser.email)
    }
  }
}

export default datadogLogs
